import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { render } from '@testing-library/react';
import { Table, Spin, message, DatePicker, Space, Tag, Radio, Button } from 'antd';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { AutoCenter } from 'antd-mobile'

const EmployeeAttendanceList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD')); // Initialize with today's date
  const [department, setDepartment] = useState('ร้อย.บก.');

  // Ref for the component to print
  const tableRef = useRef();

  // Function to handle printing using react-to-print
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  useEffect(() => {
    fetchEmployeeAttendance(department, selectedDate);
  }, [department, selectedDate]);

  const fetchEmployeeAttendance = async (selectedDepartment, date) => {
    setLoading(true);
    try {
      // console.log('selected date: ' + date);
      const response = await axios.get(`https://pos-time-attendance-be.vdqx78.easypanel.host/api/attendance-bydate?date=${date}&department=${selectedDepartment}`);
      //console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching employee attendance:', error);
      message.error('Failed to fetch employee attendance.');
    } finally {
      setLoading(false);
    }
  };

  // Format the timestamp in 24-hour format and Bangkok timezone
  const formatDatetamp = (timestamp) => {

    if (!timestamp) return 'ยังไม่ลงเวลา';

    const date = new Date(timestamp);

    return new Intl.DateTimeFormat('en-gb', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
      hour12: false, // 24-hour format
    }).format(date);
  };

  // Format the timestamp in 24-hour format and Bangkok timezone
  const formatTimeStamp = (timestamp) => {

    if (!timestamp) return 'ยังไม่ลงเวลา';

    const date = new Date(timestamp);

    return new Intl.DateTimeFormat('en-gb', {
      timeZone: 'asia/bangkok',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // 24-hour format
    }).format(date);
  };

  /**
   * Function to convert a time string in the format HH:MM:SS to seconds.
   * @param {string} timeStr - The time string in the format HH:MM:SS.
   * @returns {number} The total number of seconds.
   */
  function timeStringToSeconds(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  /**
 * Function to check if time1 is greater than or equal to time2.
 * @param {string} time1 - The first time string in the format HH:MM:SS.
 * @param {string} time2 - The second time string in the format HH:MM:SS.
 * @returns {boolean} True if time1 is greater than or equal to time2, otherwise false.
 */
  function isTimeGreaterOrEqual(time1, time2) {
    const time1InSeconds = timeStringToSeconds(time1);
    const time2InSeconds = timeStringToSeconds(time2);
    return time1InSeconds > time2InSeconds;
  }

  const columns = [
    {
      title: 'ยศ-ชื่อ',
      dataIndex: 'employee',
      key: 'employee',
      responsive: ['xs', 'sm', 'md', 'lg'], // Visible on all sizes
    },
    {
      title: 'นามสกุล',
      dataIndex: 'last_name',
      key: 'last_name',
      responsive: ['xs', 'sm', 'md', 'lg'], // Visible on all sizes
    },
    {
      title: 'สังกัด',
      dataIndex: 'department',
      key: 'department',
      responsive: ['sm', 'md', 'lg'], // Hide on extra-small screens
    },
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      render: (date) => formatDatetamp(date),
      responsive: ['sm', 'md', 'lg'], // Hide on extra-small screens
    },
    {
      title: 'ลา',
      dataIndex: 'IsLeave',
      key: 'IsLeave',
      render: (value) => value == 'Yes' ? <Tag color="orange">ลา</Tag> : '-'
    },
    {
      title: 'เวลาเข้า',
      dataIndex: 'in_time',
      key: 'in_time',
      render: (date) => isTimeGreaterOrEqual(formatTimeStamp(date), '08:30:00') ? <Tag color="red">{formatTimeStamp(date)}</Tag> : <Tag color="green">{formatTimeStamp(date)}</Tag>,
    },
    {
      title: 'เวลาออก',
      dataIndex: 'out_time',
      key: 'out_time',
      render: (date) => formatTimeStamp(date),
      responsive: ['sm', 'md', 'lg'], // Hide on extra-small screens
    },
    {
      title: 'รวมเวลาทำงาน',
      dataIndex: 'total_working',
      key: 'total_working',
      responsive: ['sm', 'md', 'lg'], // Hide on extra-small screens
    }
  ];

  const handleDepartmentChange = async (value) => {
    const selectedDepartment = value.target.value;
    setDepartment(selectedDepartment);
    fetchEmployeeAttendance(selectedDepartment, selectedDate);
  }

  return (
    <div class="container" style={{ marginTop: 50, marginBottom: 50 }}>
      <AutoCenter><h1 style={{ fontFamily: 'Prompt' }}>รายงานการเข้างาน</h1></AutoCenter>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 50, padding: 20 }}>
        <div />
        <Space>
          <Radio.Group value={department} onChange={handleDepartmentChange}>
            <Radio.Button value="ร้อย.บก.">ร้อย.บก.</Radio.Button>
            <Radio.Button value="ร้อย.1">ร้อย.1</Radio.Button>
            <Radio.Button value="ร้อย.2">ร้อย.2</Radio.Button>
            <Radio.Button value="ร้อย.3">ร้อย.3</Radio.Button>
          </Radio.Group>
          <DatePicker
            defaultValue={dayjs()}
            onChange={(date, dateString) => setSelectedDate(dateString)}
            format="YYYY-MM-DD"
          />
          <Button onClick={handlePrint} type="primary">
            พิมพ์
          </Button>
        </Space>
      </div>
      <Spin spinning={loading}>
        <div ref={tableRef}>
          <Table
            columns={columns}
            dataSource={data}
            rowKey="employee"
            pagination={{ pageSize: 10 }}
            style={{ marginTop: 20 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default EmployeeAttendanceList;
