import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spin, Result, Empty, Layout, Menu, Row, Col, Card, List, Drawer, Space, Button, Input, Image, Divider, Flex, Descriptions, Typography, message } from 'antd';
import { Popup, SearchBar, NavBar, Toast, CenterPopup } from 'antd-mobile'
import { Swiper } from 'antd-mobile';
import ProductCard from './ProductCard';
import axios from 'axios';
import endpoints from '../apis/apiConfig';
import ProductItem from './ProductItem';
import './styles.css';
import ProductDetail from './ProductDetail';
import { ShoppingCartOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem(`token`);
      const response = await axios.get(endpoints.products,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      //console.log(response);
      setProducts(response.data);
    } catch (error) {
      message.error('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  const onSelect = (product) => {
    console.log('product', `?isShowModal=true&productId=${product.id}`);
    setSelectedProduct(product);
    // navigate(`?isShowModal=true&productId=${product._id}`)
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ padding: '5px', marginTop: 50, marginBottom: 180 }}>
      <div class="product-grid">
        {products.map(product => (
          <ProductItem key={product.id} item={product} onSelect={(onSelect)} />
        ))}
      </div>
      <Popup
        showCloseButton={true}
        title="รายละเอียดสินค้า"
        position='bottom'
        visible={open}
        onClose={onClose}
        closeOnSwipe={true}
        bodyStyle={{
          maxHeight: '70vh', // Set your desired max height here
          overflowY: 'auto',  // Enable scrolling if content exceeds max height
        }}
      >
        <Content type="flex" justify="center" style={{
          overflow: 'auto',
          width: '100%',
          paddingBottom: '80px',
        }}>
          {selectedProduct && (<ProductDetail productInfo={selectedProduct} productId={selectedProduct.id} onClose={onClose} />)}
        </Content>
        <Button type="primary"
          style={{
            width: '100%',
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '0px',
            backgroundColor: 'yellow',
            color: 'black',
            fontFamily: 'Prompt',
          }}
          onClick={() => onClose()}
          block
        >
          ปิดหน้าต่าง
        </Button>
      </Popup>
    </div>
  );
};

export default ProductList;
