import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, message } from 'antd';
import { ImageUploader, Space, Toast, Dialog, ImageUploadItem, ImageUploaderRef, Button } from 'antd-mobile'
import { ConfigProvider, ErrorBlock } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import endpoints from '../apis/apiConfig';


// LINE LIFF initialization
const liff = window.liff;
const liffId = '2004914017-mWBvVB50';


const CreateProduct = () => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [productImage, setProductImage] = useState('');
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);

  // Effect hook to initialize LIFF and fetch user profile
  useEffect(() => {
    const initializeLiff = async () => {
      try {
        setLoading(true);
        await liff.init({ liffId });
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          setProfile(profile);
          setLoading(false);
        } else {
          liff.login();
          setLoading(false);
        }
      } catch (err) {
        console.error('LIFF initialization failed', err);
        setLoading(false);
      }
    };

    initializeLiff();

  }, []); // Empty dependency array to run effect only once after component mounts


  async function mockUpload(file) {
    console.log(file);

    // Create a new FormData instance
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', 'product');
    formData.append('key', '00043c074fc6325e6fa2fd8bb1969ee9');

    // Make an API call to upload the image using Axios
    //https://thumbsnap.com/api
    const response = await axios.post('https://www.atom-soft.com/image-api/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Assuming the response contains the uploaded image URL
    console.log('file uploaded', response.data);
    setProductImage(response.data.filename);


    return {
      url: URL.createObjectURL(file),
    }
  }


  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleFormSubmit = async (values) => {

    setLoading(true);
    try {
      await axios.post(endpoints.products, { ...values, image_url: productImage, lineUserId: profile.userId });
      message.success('เพิ่มสินค้าสำเร็จ!');
      navigate('/store');
    } catch (error) {
      message.error('Failed to create product');
    } finally {
      setLoading(false);
    }

  };



  return (
    <Form layout="vertical" onFinish={handleFormSubmit} style={{ width: '100%', padding: '20px', marginBottom: 50, marginTop: 50, color:'white' }}>
      <Form.Item
        name="name"
        label="ชื่อสินค้า"
        style={{color:'white'}}
        rules={[{ required: true, message: 'กรุณาป้อนชื่อสินค้า' }]}
      >
        <Input placeholder="กรุณาป้อนชื่อสินค้า" />
      </Form.Item>

      <Form.Item
        name="description"
        label="รายละเอียดสินค้า"
      >
        <Input.TextArea placeholder="กรุณาใส่รายละเอียดสินค้า" />
      </Form.Item>

      <Form.Item
        name="price"
        label="ราคาขาย"
        rules={[{ required: true, message: 'ป้อนจำนวนราคาขาย' }]}
      >
        <InputNumber
          min={0}
          placeholder="ราคาที่ขาย"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        name="stock"
        label="จำนวน"
      >
        <InputNumber
          min={0}
          placeholder="กรุณาป้อนจำนวน"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        name="image_url"
        label="รูปสินค้า"
      >
        <ConfigProvider locale={enUS}>
          <ImageUploader
            style={{ '--cell-size': '90px' }}
            // beforeUpload={() => false} // Prevent auto-upload
            value={fileList}
            onChange={handleUploadChange}
            upload={mockUpload}
          />
        </ConfigProvider>
      </Form.Item>

      <Form.Item>
        <Button color='primary' size='large' type='submit' loading={loading} block style={{ fontFamily: 'Prompt' }}>
          ลงประกาศขาย
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProduct;
