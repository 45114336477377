import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Typography, Divider, Image } from 'antd';
import axios from 'axios';
import { ShoppingCartOutlined } from '@ant-design/icons';
import endpoints from '../apis/apiConfig';
import { CartContext } from '../context/CartContext';
import { formatCurrency } from '../utils/formatNumber';
import styled from 'styled-components';

const { Title, Text } = Typography;

const ProductDetail = ({ productInfo, productId }) => {
    // const { productId } = useParams();
    const { addToCart } = useContext(CartContext);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${endpoints.products}/${productId}`);
                setProduct(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching product data:", error);
            }
        };

        fetchProduct();
    }, [productId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <Card>
            <Row gutter={16}>
                <Col xs={24} sm={12}>
                   {product.image_url?(<Image
                        preview={true}
                        src={`https://www.atom-soft.com/image-api/products/${product.image_url}`}
                        alt={product.name}
                        height={150}
                        maxHeight={150}

                    />):(<ImgText>{product.name}</ImgText>)}
                </Col>
                <Col xs={24} sm={12}>
                    <Title level={2}>{product.name}</Title>
                    <Barcode style={{ color: 'GrayText' }}>ผู้ขาย: {`${productInfo.first_name}  ${productInfo.last_name}`}</Barcode>
                    <Barcode style={{ color: 'GrayText' }}>โทร: {`${productInfo.phone}`}</Barcode>
                    <Divider />
                    <Title level={4} style={{ color: 'red' }}>ราคา: {formatCurrency(product.price)} บาท</Title>
                    <Text>{product.description}</Text>
                    <Divider />
                    <Button type="primary"
                        icon={<ShoppingCartOutlined />}
                        onClick={() => addToCart(product)}
                        block
                    >
                        หยิบใส่ตะกร้า
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

export default ProductDetail;


const Barcode = styled.div`
  font-size: 14px;
  font-weight: regular;
  margin: 0px 0;
  color: #131313;
  height: 20px; /* Fixed height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ImgText = styled.div`
  width: auto;
  height: 150px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #62676cb8;
`;
