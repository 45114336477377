import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../utils/formatNumber';
const noImage = 'https://via.placeholder.com/200'; // Placeholder image URL

const ProductItem = ({ item, onSelect }) => {

  return (
    <Item onClick={() => onSelect(item)} style={{ cursor: 'pointer'}}>
      <ImageWrapper>
        {item.image_url? (
          <>
            <img
              src={item.image_url ? `https://www.atom-soft.com/image-api/products/${item.image_url}` : noImage}
              alt={item.name} height={150} width={'100%'}
              style={{objectFit: 'cover'}}
              />
          </>
        ) : (
          <>
            <ImgText>
              {item.name}
            </ImgText>
          </>
        )}
      </ImageWrapper>
      <Info>
        <Name style={{ marginTop: '30px'}}>{item.name}</Name>
        <Barcode style={{color: 'GrayText'}}>ผู้ขาย: {`${item.first_name}  ${item.last_name}`}</Barcode>
        <Barcode style={{color: 'GrayText'}}>โทร: {`${item.phone}`}</Barcode>
        <Price>{formatCurrency(item.price)}</Price>
        <CartButton onClick={() => onSelect(item)}>รายละเอียด</CartButton>
      </Info>
    </Item>
  );
};

export default ProductItem;

const ImgText = styled.div`
  width: auto;
  height: 150px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #62676cb8;
`;

const Item = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 150px;
  text-align: center;
`;

const Info = styled.div`
  padding: 15px;
  text-align: center;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 0px 0;
  margin-top: 0px;
  color: #333;
  height: 30px; /* Fixed height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #FFFFFF;
`;

const Barcode = styled.div`
  font-size: 14px;
  font-weight: regular;
  margin: 0px 0;
  color: #131313;
  height: 20px; /* Fixed height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Price = styled.div`
  font-size: 18px;
  color: #e91e63;
`;

const CartButton = styled.a`
  display: inline-block;
  margin-top: 1px;
  padding: 10px 15px;
  background-color: yellow;
  color: black;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
  &:hover {
    background-color: white;
  }
`;