import L from 'leaflet';
import React, { useState, useEffect } from 'react';
import { DemoBlock } from '../Home/demos-util';
import { AutoCenter, Avatar, Divider, Button, Dialog, Space, Toast, SpinLoading, Modal, Form, Input, } from 'antd-mobile';
import axios from 'axios';
import { ConfigProvider } from 'antd-mobile';
import TimeDisplay from '../Components/Timedisplay';
import RegistrationForm from '../Components/RegistrationForm';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// LINE LIFF initialization
const liff = window.liff;
const liffId = '2004914017-mWBvVB50';

const Attedance = () => {
  // State hooks for user profile information
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [lastType, setLastType] = useState('');
  const [isLoading, setIsloading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [name, setName] = useState('');
  const [userLineID, setUserLineID] = useState(null);
  const [pictureUrl, setPictureUrl] = useState('');
  const [userExists, setUserExists] = useState(null); // null means we haven't checked yet
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  // const [position, setPosition] = useState(null);

  // Effect hook to initialize LIFF and fetch user profile
  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId });
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          setProfile(profile);
          setName(profile.displayName);
          setUserLineID(profile.userId);
          setPictureUrl(profile.pictureUrl);
          setIsloading(false);
        } else {
          liff.login();
          setIsloading(false);
        }
      } catch (err) {
        console.error('LIFF initialization failed', err);
        setIsloading(false);
      }
    };

    initializeLiff();

  }, []); // Empty dependency array to run effect only once after component mounts


  //get gps location from device
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (pos) => {
  //         const { latitude, longitude } = pos.coords;
  //         setPosition([latitude, longitude]);
  //       },
  //       (error) => {
  //         console.error('Error obtaining location:', error);
  //       }
  //     );
  //   }
  // }, []);

  // checkinig user is already registered
  useEffect(() => {
    const checkUserExists = async () => {
      try {
        const response = await axios.get(`https://pos-time-attendance-be.vdqx78.easypanel.host/api/check/${userLineID}`);
        if (response.data.exists) {
          setUserExists(true);
        } else {
          setUserExists(false);
          setShowRegistrationForm(true);
        }
      } catch (error) {
        console.error('Error checking user existence:', error);
      }
    };

    const fetchLatestTimestamp = async () => {
      try {
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          console.log('Fetching latest timestamp', userLineID);
          const response = await axios.get(`https://pos-time-attendance-be.vdqx78.easypanel.host/api/last-timestamp?userId=${profile.userId}`);
          setLastTimestamp(response.data.lastTimestamp);
          setLastType(response.data.lastType)
        }

      } catch (error) {
        //setError(error.message);
      } finally {
        setIsloading(false);
      }
    };


    if (userLineID) {
      fetchLatestTimestamp();
      checkUserExists();
    }

  }, [userLineID]);

  function getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
    const R = 6371000; // Radius of the Earth in meters
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in meters
    return distance;
  }

  function toRad(degrees) {
    return (degrees * Math.PI) / 180;
  }

  // Handle QR Code Scanning
  const handleScanQrCode = async () => {
    try {

      setIsloading(true);

      await axios.post('https://pos-time-attendance-be.vdqx78.easypanel.host/api/attendance', {
        userId: userLineID,
        type: lastType === 'เข้า' ? 'ออก' : 'เข้า',
      });

      Dialog.alert({
        content: lastType === 'เข้า' ? 'ลงเวลาออกงานสำเร็จ' : 'ลงเวลาเข้างานสำเร็จ',
        closeOnMaskClick: true,
        style: { fontFamily: 'Prompt'},
        confirmText: 'รับทราบ'
      })

      setIsloading(false);
      setLastType(lastType === 'เข้า' ? 'ออก' : 'เข้า')

      const response = await axios.get(`https://pos-time-attendance-be.vdqx78.easypanel.host/api/last-timestamp?userId=${userLineID}`);
      setLastTimestamp(response.data.lastTimestamp);
      setLastType(response.data.lastType)

    } catch (err) {
      console.error('Error scanning QR code:', err);
      //setError('Error scanning QR code.');
      setIsloading(false);
    }
  };

  const theme = {
    fontFamily: 'Prompt',
  };

  // Format the timestamp in 24-hour format and Bangkok timezone
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'ยังไม่ลงเวลา';
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-gb', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'asia/bangkok',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // 24-hour format
    }).format(date);
  };

  function convertToISOFormat(dateStr) {
    // Split the input date string into date and time components
    const [datePart, timePart] = dateStr.split(', ');

    // Split the date part into day, month, and year
    const [day, month, year] = datePart.split('/');

    // Construct the ISO date string
    const isoDateStr = `${year}-${month}-${day}T${timePart}`;

    return isoDateStr;
  }

  // Helper function to set the map view to the current location
  // function SetViewOnClick({ coords }) {
  //   const map = useMap();
  //   useEffect(() => {
  //     if (coords) {
  //       map.setView(coords, map.getZoom());
  //     }
  //   }, [coords, map]);

  //   return null;
  // }

  return (
    <ConfigProvider theme={theme} style={{ fontFamily: 'Prompt' }}>
      <AutoCenter style={{ marginTop: '50px' }}>
        <Avatar src={pictureUrl} style={{ borderRadius: 100, width: 100, height: 100, margin: 'auto' }} />
        <Divider>{name}</Divider>
        <TimeDisplay lastType={lastType} lastTimestamp={convertToISOFormat(formatTimestamp(lastTimestamp))} />
        {isLoading ? (
          <>
            <DemoBlock style={{ fontFamily: 'Prompt' }} title='กรุณารอสักครู่...'>
              <AutoCenter>
                <Space direction='horizontal' wrap block style={{ '--gap': '16px' }}>
                  <SpinLoading style={{ '--size': '48px' }} color='primary' />
                </Space>
              </AutoCenter>
            </DemoBlock>
          </>
        ) : (
          <>
            <div style={{ fontFamily: 'Prompt', margin: 20, fontSize: 18, marginBottom: '5px', color:'white' }}>ลงเวลา{lastType} ล่าสุดเมื่อ:</div>
            <h2 style={{ fontFamily: 'prompt-Bold', margin: 20, fontSize: 18, marginTop: 3, color:'white' }}>{formatTimestamp(lastTimestamp)}</h2>
            <Button block color={lastType === 'เข้า' ? 'danger' : 'primary'} size='large' onClick={handleScanQrCode} loading={isLoading} style={{ fontFamily: 'Prompt', color:'white' }}>
              {lastType === 'เข้า' ? 'ลงเวลาออกงาน' : 'ลงเวลาเข้างาน'}
            </Button>
          </>
        )}
        <Button block color={'primary'} size='large' onClick={() => setShowRegistrationForm(true)} loading={isLoading} style={{ fontFamily: 'Prompt', marginTop: 10 }}>
          แก้ไขข้อมูลส่วนตัว
        </Button>
      </AutoCenter>

      <Modal
        visible={showRegistrationForm}
        content={userLineID && <RegistrationForm userLineID={userLineID} onSuccess={()=>setShowRegistrationForm(false)}/>}
        closeOnAction
        onClose={() => setShowRegistrationForm(false)}
        minHeight={400}
        minWidth={'100vh'}
        style={{ fontFamily: 'Prompt' }}
        actions={[
          {
            key: 'save',
            text: 'ปิด',
            style: { fontFamily: 'Prompt' }
          },
        ]}
      />
    </ConfigProvider>
  );
};



export default Attedance;
