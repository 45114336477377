import React, { useState, useEffect } from 'react';

const TimeDisplay = ({ lastTimestamp, lastType }) => {
  // State to hold the current time
  const [time, setTime] = useState(new Date());

  // Function to update the time
  const updateTime = () => {
    setTime(new Date());
  };

  // Use effect to set up a timer to update the time every second
  useEffect(() => {
    const timer = setInterval(updateTime, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  // Format the time to 24-hour format (HH:mm:ss)
  const formattedTime = time.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // 24-hour format
  });

  function calculateTotalTimeDifference(startTime, endTime) {
    try {
      // Convert input to Date objects if they are strings
      const start = typeof startTime === 'string' ? new Date(startTime) : startTime;
      const end = typeof endTime === 'string' ? new Date(endTime) : endTime;

      // Calculate the difference in milliseconds
      const diffInMilliseconds = end - start;

      // Ensure that the difference is not negative
      if (diffInMilliseconds < 0) {
        throw new Error('End time must be after start time');
      }

      // Convert milliseconds to total seconds
      const totalSeconds = Math.floor(diffInMilliseconds / 1000);

      // Calculate hours, minutes, and seconds
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // Format the time as "H:mm:ss"
      const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      return formattedTime;
    } catch (e) {
      console.error('Error calculating time difference:', e);
      return 'Error';
    }
  }



  return (
    <div style={{ fontFamily: 'Prompt' }}>
      <h1 style={{ fontFamily: 'Prompt', textAlign: 'center' }}>{formattedTime}</h1>
      {lastType === 'เข้า'&&<h3 style={{ fontFamily: 'Prompt', textAlign: 'center' }}>รวมเวลาเข้างาน {calculateTotalTimeDifference(lastTimestamp, time)}</h3>} 
    </div>
  );
};

export default TimeDisplay;
