// src/Routes.js
import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EmployeeAttendanceList from './Attedance/EmployeeAttendanceList';
import Attedance from './Attedance/Attedance';
import HomeScreen from './Home/HomeScreen';
import ProductManagement from './ProductManagement/ProductManagement';
import ProductList from './Products/ProductList';
import ProductDetail from './Products/ProductDetail';
import CreateProduct from './ProductManagement/CreateProduct';
import EditProduct from './ProductManagement/EditProduct';
import Checkout from './Checkout/Checkout';
import { NavBar, TabBar } from 'antd-mobile'
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  MemoryRouter as Router,
} from 'react-router-dom'
import {
  AppOutline,
  MessageOutline,
  UnorderedListOutline,
  UserOutline,
  ClockCircleOutline,
  AppstoreOutline
} from 'antd-mobile-icons'
import './App.css'
import MobileLayout from './Layout/mobileLayout'
import { CartProvider } from './context/CartContext';

const AppRoutes = () => {

  return (
    <Router initialEntries={['/home']}>
      <CartProvider>
        <MobileLayout>
          <Routes>
            <Route path="/" element={<ProductManagement />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/attedance" element={<Attedance />} />
            <Route path="/report" element={<EmployeeAttendanceList />} />
            <Route path="/store" element={<ProductList />} />
            <Route path="/product-management" element={<ProductManagement />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/products/create" element={<CreateProduct />} />
            <Route path="/products/edit/:id" element={<EditProduct />} />
            <Route path="/cart" element={<Checkout />} />
          </Routes>
        </MobileLayout>
      </CartProvider>
    </Router>
  );
};

function Todo() {
  return <div>待办</div>
}

function Message() {
  return <div>消息</div>
}

function PersonalCenter() {
  return <div>我的</div>
}

export default AppRoutes;
