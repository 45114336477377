import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { CartContext } from '../context/CartContext';
import { formatCurrency } from '../utils/formatNumber';
import './ProductCard.css';

const { Meta } = Card;

const ProductCard = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const navigate  = useNavigate();

  // console.log(product)

  return (
    <Card
      hoverable
      cover={<img alt={product.name} src={`https://www.atom-soft.com/image-api/products/${product.image_url}`}  onClick={() => navigate(`/product/${product.id}`)} style={{marginTop: '20px'}}/>}
      actions={[
        <Button
          type="primary"
          icon={<ShoppingCartOutlined />}
          onClick={() => addToCart(product)}
        >
          หยิบใส่ตะกร้า
        </Button>,
      ]}
    >
      <Meta
        onClick={() => navigate(`/product/${product.id}`)}
        title={product.name}
        description={
          <div>
            <p className="product-price">{formatCurrency(product.price)} บาท</p>
            {/* <p className="product-sold">ขายไปแล้ว {product.sold} รายการ</p> */}
          </div>
        }
      />
    </Card>
  );
};

export default ProductCard;
