import React, { useState, useEffect } from 'react';
import { Divider, NavBar, Swiper, Grid, Card, TabBar, SpinLoading, Image, Space, Modal, Popup, Button } from 'antd-mobile';
import { ShopOutlined, ShoppingCartOutlined, HistoryOutlined, ContainerOutlined, PlusCircleOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { ScanCodeOutline } from 'antd-mobile-icons'
import { AutoCenter } from 'antd-mobile'
import {
    Link,
    useNavigate,
} from 'react-router-dom'
import axios from 'axios';
import './Home.css';

const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']

const NewsCard = ({ title, imageUrl, description }) => {
    return (
        <Card
            style={{ width: '100%', position: 'relative' }}
            bodyStyle={{ padding: '0' }}
        >
            <Image
                src={imageUrl}
                alt={title}
                width="100%"
                height={200}
                fit="cover"
                style={{ borderRadius: '0px' }}
            />
            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                background: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
                padding: '10px',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
            }}>
                <h3 style={{ margin: 0 }}>{title}</h3>
            </div>
        </Card>
    );
};



const HomeScreen = () => {
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [arty6posts, setArty6Posts] = useState([]);
    const [error, setError] = useState(null);
    const [visible, setVisible] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);


    const handleOpenModal = (post) => {
        setSelectedPost(post);
        setVisible(true);
    };

    const handleCloseModal = () => {
        setVisible(false);
        setSelectedPost(null);
    };

    useEffect(() => {
        // Replace with your WordPress site's REST API URL
        const fetchPosts = async () => {
            try {
                const response = await axios.get('https://rta.mi.th/wp-json/wp/v2/posts');
                console.log(response.data);
                setPosts(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchPosts();

        console.log('Fetching posts...');


    }, []);

    useEffect(() => {
        // Replace with your WordPress site's REST API URL
        const fetchPosts = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://arty6.net/home/wp-json/wp/v2/posts');
                console.log('Arty6 Post', response.data);
                setArty6Posts(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchPosts();

        console.log('Fetching posts...');


    }, []);

    const features = [
        { icon: <ScanCodeOutline />, text: 'ลงเวลาเข้างาน', route: '/attedance' },
        { icon: <ShopOutlined />, text: 'ตลาดพันปืน', route: '/store' },
        { icon: <UserOutlined />, text: 'บัญชี', route: '/attedance' },
    ];

    if (loading) {
        return <Space direction='horizontal' wrap block style={{ '--gap': '16px' }}>
            <SpinLoading color='default' />
        </Space>;
    }

    return (
        <div style={{ padding: '5px', marginTop: 0, marginBottom: 500, width: '100vh', height: '80vh' }}>
            <AutoCenter style={{ marginTop: 50 }}>
                <Swiper
                    style={{
                        '--border-radius': '8px',
                        height: 100,
                        backgroundColor: 'yellow',
                        marginTop: '80px',
                        padding: '5px',
                        paddingTop: '30px',
                        margin: '4px',
                        border: '1px solid',
                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
                        borderRadius: '10px',
                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
                        color: 'black',
                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
                        width: '330px',
                    }}
                    defaultIndex={3}>
                    <Swiper.Item key={'1'}>
                        <div style={{ height: 100 }}>
                            <Grid columns={3} gap={5}>
                                {features.map((item, index) => (
                                    <Grid.Item key={index} onClick={() => navigate(item.route)}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div style={{ fontSize: 24, color: 'black' }}>{item.icon}</div>
                                            <div style={{ marginTop: 8, color: 'black' }}>{item.text}</div>
                                        </div>
                                    </Grid.Item>))}
                            </Grid>
                        </div>
                    </Swiper.Item>
                </Swiper>
            </AutoCenter>

            <Divider style={{ marginTop: '10px', color: 'yellow', width: '300px' }} contentPosition='left'>ข่าวประชาสัมพันธ์</Divider>
            <div style={{ padding: '5px', marginTop: 0, marginBottom: 180, width: '300px', margin: 'auto' }}>
                <Swiper style={{ marginTop: '5px' }}>
                    {posts.map((item, index) => (
                        <Swiper.Item key={index} onClick={() => handleOpenModal(item)}>
                            <NewsCard
                                title={item.title.rendered}
                                imageUrl={item.better_featured_image.source_url}
                                description={item.description}
                            />
                        </Swiper.Item>
                    ))}
                </Swiper>
            </div>


            <Divider style={{ marginTop: '10px', color: 'yellow', width: '300px' }} contentPosition='left'>ข่าวสารกองพัน</Divider>
            <div style={{ padding: '5px', marginTop: 0, marginBottom: 500, width: '300px', margin: 'auto' }}>
                <Swiper style={{ marginTop: '5px' }}>
                    {arty6posts.map((item, index) => (
                        <Swiper.Item key={index} onClick={() => handleOpenModal(item)}>
                            <NewsCard
                                title={item.title.rendered}
                                imageUrl={item.jetpack_featured_media_url}
                                description={item.description}
                            />
                        </Swiper.Item>
                    ))}
                </Swiper>
            </div>
            <Popup
                visible={visible}
                onMaskClick={handleCloseModal}
                onClose={handleCloseModal}
                bodyStyle={{ padding: '0px', height: '80vh', maxWidth: '80vh' }}
                style={{ maxWidth: '100%' }}
                showCloseButton={true}
            >
                <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                    <Space direction="vertical" style={{ width: '100%', height: '100%', padding: '0px' }}>
                        <h3 style={{ margin: '0', padding: '16px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}>
                            {selectedPost ? selectedPost.title.rendered : ''}
                        </h3>
                        {selectedPost && (
                            <div
                                dangerouslySetInnerHTML={{ __html: selectedPost.content.rendered }}
                                style={{ padding: '16px', fontFamily: 'Prompt, sans-serif', padding: '50px' }}
                            />
                        )}
                        <AutoCenter >
                            <Button type='Primary' onClick={handleCloseModal} style={{ fontFamily: 'Prompt', margin: 'auto', width: '300px', backgroundColor: 'yellow', color: 'black', marginBottom: '300px' }}>ปิดหน้าต่าง</Button>
                        </AutoCenter>

                    </Space>
                </div>
            </Popup>

            <style jsx global>{`
        /* Ensure images in the Popup content do not respond to clicks */
        .am-popup-body img {
          max-width: 100%;
          height: auto;
          pointer-events: none; /* Disable clicks on images */
        }

        /* Change the font family for content */
        .am-popup-body {
          font-family: Prompt, sans-serif;
        }
      `}</style>


        </div>
    );
};

export default HomeScreen;
