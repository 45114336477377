// src/App.js
import React from 'react';
import AppRoutes from './Routes';
import { ConfigProvider } from 'antd';

const App = () => {
  return (
    <ConfigProvider theme={{
      token: {
        fontFamily: "Prompt",
        color: 'white',
      }
    }}>
        <AppRoutes />
    </ConfigProvider>
  );
};

export default App;
