import React, { useState, useEffect } from 'react';
import { Table, Button, Popconfirm, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import endpoints from '../apis/apiConfig';

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(endpoints.products);
      setProducts(response.data);
    } catch (error) {
      message.error('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  const deleteProduct = async (id) => {
    try {
      await axios.delete(`${endpoints.products}/${id}`);
      message.success('Product deleted successfully');
      fetchProducts(); // Refresh the product list
    } catch (error) {
      message.error('Failed to delete product');
    }
  };

  const columns = [
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'ราคา',
      dataIndex: 'price',
      key: 'price',
      render: (text) => `฿${text}`,
    },
    {
      title: 'จำนวน',
      dataIndex: 'stock',
      key: 'stock',
    },
    {
      title: 'ดำเนินการ',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => navigate(`/products/edit/${record.id}`)}>
            แก้ไข
          </Button>
          <Popconfirm
            title="Are you sure to delete this product?"
            onConfirm={() => deleteProduct(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              ลบ
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        style={{ marginBottom: 16 }}
        onClick={() => navigate('/products/create')}
      >
        เพิ่มสินค้าใหม่
      </Button>
      <Table columns={columns} dataSource={products} rowKey="id" loading={loading} />
    </div>
  );
};

export default ProductManagement;
