import React, { useEffect, useState } from 'react';
import TopCenteredLayout from '../Components/TopCenteredLayout';
import { Button, Result, Card, List, Divider, Typography, notification, message } from 'antd';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useCart } from '../context/CartContext'; // Assume you're using a CartContext for cart management
import { useNavigate } from 'react-router-dom';
import endpoints from '../apis/apiConfig';

// LINE LIFF initialization
const liff = window.liff;
const liffId = '2004914017-mWBvVB50';

const { Title } = Typography;

const Payment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [profile, setProfile] = useState(null);
    const { clearCart } = useCart(); // Function to clear the cart
    const { cartItems } = location.state || { cartItems: [] };
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [chargeId, setChargeId] = useState('');
    const [orderId, setOrderId] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('Processing');
    const [loading, setLoading] = useState(false);

     // Effect hook to initialize LIFF and fetch user profile
  useEffect(() => {
    const initializeLiff = async () => {
      try {
        setLoading(true);
        await liff.init({ liffId });
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          setProfile(profile);
          setLoading(false);
        } else {
          liff.login();
          setLoading(false);
        }
      } catch (err) {
        console.error('LIFF initialization failed', err);
        setLoading(false);
      }
    };

    initializeLiff();

  }, []); // Empty dependency array to run effect only once after component mounts

    // Calculate the total amount
    const totalAmount = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);


    const handleCheckout = async () => {
        setLoading(true);
        try {
            const lineUserId = profile.userId;

            const response = await axios.post(endpoints.createOrder, {
                lineUserId,
                cartItems,
                totalAmount
            });

            setOrderId(response.data.orderId);

            // Clear the cart
            clearCart();

            //alert('Order created successfully!');
            notification.success({
                message: 'สร้างคำสั่งซื้อสำเร็จ',
                description: 'Order created successfully!',
            });

            scrollToBottom();

        } catch (error) {
            console.error('Error creating order:', error);
            alert('Failed to create order');
        } finally {
            setLoading(false);
        }
    };


    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth' // Optional: Adds smooth scrolling
        });
    };

    useEffect(() => {

        //console.log('cartItems', cartItems);

        // Calculate the total amount in Satang (1 Baht = 100 Satang)
        const totalAmountInBaht = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
        const totalAmountInSatang = totalAmountInBaht * 100; // Convert to Satang

        // Order details to be sent to the backend
        const orderDetails = {
            amount: totalAmountInSatang,
            currency: 'THB',
            description: 'ยืนยันคำสั่งซื้อ',
            items: cartItems.map(item => ({
                name: item.name,
                quantity: item.quantity,
                price: item.price,
            })),
        };


    }, [cartItems]);

    return (
        <TopCenteredLayout>
            <div style={{ padding: '24px', maxWidth: '600px', margin: '0 auto' }}>
                <Card title="สรุปการสั่งซื้อ" bordered={false}>
                    <List
                        itemLayout="horizontal"
                        dataSource={cartItems}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.name}
                                    description={`Quantity: ${item.quantity} x ${item.price}`}
                                />
                                <div>{(item.price * item.quantity)}</div>
                            </List.Item>
                        )}
                    />
                    <Divider />
                    <Title level={4} style={{ textAlign: 'right' }}>
                        รวมเป็นเงิน: {totalAmount.toFixed(2)}
                    </Title>
                    <Button
                        type="primary"
                        block
                        onClick={handleCheckout}
                        disabled={orderId ? true : false}
                    >
                        ยืนยันคำสั่งซื้อ
                    </Button>
                </Card>
            </div>
            {orderId && <Result
                status="success"
                title={`คำสั่งซื้อที่  ${orderId}`}
                subTitle="ได้รับคำสั่งซื้อเรียบร้อย"
                // extra={[
                //     qrCodeUrl && <img src={qrCodeUrl} alt="PromptPay QR Code" width={'100vh'} style={{ minWidth: '250px' }} />,
                // ]}
            />
            }



        </TopCenteredLayout>
    );
};

export default Payment;
